<template>
  <div class="footer">
    
    <a :href="linkedInUrl" target="_blank">
      <i class="fab fa-linkedin-in"></i>
      <img :src="profileImg" :alt="profileName">
    </a>
    
    <a :href="'tel:'+ phoneNumber">
      <i class="fas fa-phone-alt"></i>
    </a>
    
    <a :href="'whatsapp://send?text='+ whatsApp.text +'&phone='+ whatsApp.phone +'&abid='+ whatsApp.abid">
      <i class="fab fa-whatsapp"></i>
    </a>

    <a :href="'mailto:'+ emailAddress">
      <i class="far fa-envelope"></i>
    </a>

    <button><a href="https://flow.brydg.com" target="_blank">Submit a Loan</a></button>
    
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data: function() {
    return {
      // profileName: "Peter Matthews",
      // linkedInUrl: "https://www.linkedin.com/in/peter-matthews-684195166",
      // profileImg: require("@/assets/peter-matthews.png"),
      // phoneNumber: "+447810865650",
      // emailAddress: "peter.matthews@brydg.com",
      // whatsApp: {
      //   text: '',
      //   phone: '+447810865650',
      //   abid: '+447810865650'
      // },
      // profileName: "Aysan Pamir Ozel",
      // linkedInUrl: "https://www.linkedin.com/in/aysan-pamir-ozel-8420682",
      // profileImg: require("@/assets/aysan-ozel.jpg"),
      // phoneNumber: "+447824717164",
      // emailAddress: "aysan.ozel@brydg.com",
      // whatsApp: {
      //   text: '',
      //   phone: '+447824717164',
      //   abid: '+447824717164'
      // },
      profileName: "Pedja Savovic Jovanovic",
      linkedInUrl: "https://www.linkedin.com/in/predragsavovic",
      profileImg: require("@/assets/pedja-savovic.jpg"),
      phoneNumber: "+447517931900",
      emailAddress: "pedja.savovic@brydg.com",
      whatsApp: {
        text: '',
        phone: '+447517931900',
        abid: '+447517931900'
      },
      // profileName: "Laurent Chocron",
      // linkedInUrl: "https://www.linkedin.com/in/laurent-chocron-92b70912",
      // profileImg: require("@/assets/laurent-chocron.jpg"),
      // phoneNumber: "+972549007333",
      // emailAddress: "laurent.chocron@brydg.com",
      // whatsApp: {
      //   text: '',
      //   phone: '+972549007333',
      //   abid: '+972549007333'
      // },
      // profileName: "Jack Hodgkin",
      // linkedInUrl: "https://www.linkedin.com/in/jackhodgkin",
      // profileImg: require("@/assets/jack-hodgkin.jpg"),
      // phoneNumber: "+447772489430",
      // emailAddress: "jack.hodgkin@brydg.com",
      // whatsApp: {
      //   text: '',
      //   phone: '+447772489430',
      //   abid: '+447772489430'
      // },
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer {
  width: 100%;
  max-width: 1024px;
  position: fixed;
  z-index: 2;
  bottom: 0;
  background: #fff;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 30px 30px 0 0;
  box-shadow: 0px -10px 6.72059px rgba(0, 0, 0, 0.01), 0px -30px 52.0846px -25.2022px rgba(0, 0, 0, 0.1);
}
.footer > a {
  display: flex;
  background: #C3AC6B;
  text-decoration: none;
  color: #fff;
  width: 38px;
  height: 38px;
  font-size: 20px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}
.footer > a:nth-of-type(1) {
  background: #fff;
  border: solid 1px #ccc;
  overflow: hidden;
}
.footer > a:nth-of-type(1) i {
  display: flex;
  width: 16px;
  height: 16px;
  background: #408AA1;
  border-radius: 50%;
  position: absolute;
  justify-content: center;
  align-items: center;
  font-size: .5rem;
  margin-left: -28px;
  margin-bottom: -28px;
}
.footer > a:nth-of-type(3) {
  font-size: 26px;
}
button {
  border: none;
  background: #BA4A00;
  border-radius: 60px;
  color: #fff;
  font-family: 'DM Sans', sans-serif;
  font-weight: 700;
  font-size: 18px;
  padding: .5rem 1rem;
}
button a {
  color: #fff;
  text-decoration: none;
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) { 
  .footer > a:nth-of-type(1) i {
    margin-left: -13px;
    margin-top: 13px;
  }
}
@media only screen and (min-width: 768px) {
  .footer {
    width: 100%;
    max-width: 1024px;
    position: fixed;
    z-index: 2;
    bottom: 0;
    background: #fff;
    padding: 20px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 30px 30px 0 0;
    box-shadow: 0px -10px 6.72059px rgba(0, 0, 0, 0.01), 0px -30px 52.0846px -25.2022px rgba(0, 0, 0, 0.1);
  }
  .footer > a {
    display: flex;
    background: #C3AC6B;
    text-decoration: none;
    color: #fff;
    width: 66px;
    height: 66px;
    font-size: 35px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
  }
  .footer > a:nth-of-type(1) {
    border: solid 2px #ccc;
  }
  .footer > a:nth-of-type(1) i {
    width: 33px;
    height: 33px;
    background: #408AA1;
    border-radius: 50%;
    position: absolute;
    font-size: 1rem;
    margin-left: -53px;
    margin-bottom: -53px;
  }
  .footer > a:nth-of-type(3) {
    font-size: 45px;
  }
  button {
    border: none;
    background: #BA4A00;
    border-radius: 60px;
    color: #fff;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    font-size: 20px;
    padding: 20px 25px;
  }
}
</style>